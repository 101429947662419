var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"container px-4 px-xl-5 my-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"title--secondary"},[_vm._v(" Point History ")]),_c('h2',{staticClass:"title--tertiary mb-5"},[_vm._v(" Check your point histories. ")]),(_vm.histories && _vm.histories.length > 0)?[_vm._l((_vm.histories),function(history,index){return _c('article',{key:index,staticClass:"historyItem mb-2 d-flex",class:[
                            _vm.active_id && _vm.active_id == history.id
                                ? 'addHighlight'
                                : '' ]},[(
                                history.type == 'Loyalty' ||
                                    history.type == 'LOYALTY'
                            )?_c('img',{attrs:{"src":require("@/assets/img/Royalty.png"),"alt":"point image","width":"50","height":"50"}}):_vm._e(),(history.type == 'Activity')?_c('img',{attrs:{"src":require("@/assets/img/Mining.png"),"alt":"point image","width":"50","height":"50"}}):_vm._e(),(history.type == 'REFERRAL')?_c('img',{attrs:{"src":require("@/assets/img/Referal1.png"),"alt":"point image","width":"50","height":"50"}}):_vm._e(),_c('p',{staticClass:"mb-auto mt-auto ms-2"},[_vm._v(" "+_vm._s(_vm.logForHistory( history.transaction_type, history.point, history.created_at, history.name ))+" "),(
                                    history.pointTransactionType &&
                                        history.pointTransactionType
                                            .pointable &&
                                        history.pointTransactionType
                                            .pointable.__typename == 'Order'
                                )?[_c('router-link',{attrs:{"to":{
                                        name: 'MyOrder',
                                        query: {
                                            order_id:
                                                history.pointTransactionType
                                                    .pointable.id,
                                        },
                                    }}},[_vm._v(" "+_vm._s(history.pointTransactionType .pointable.order_no)+" ")])]:_vm._e()],2)])}),_c('div',{staticClass:"text-center mt-4"},[(_vm.has_more_page)?_c('main-button',{staticClass:"btn--lg",attrs:{"name":_vm.$t('products.showmore.button')},on:{"clicked":_vm.showMore}}):_vm._e()],1)]:[_vm._v(" No Point History! ")]],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }