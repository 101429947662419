<template>
    <div class="">
        <!-- <profile-side-bar /> -->
        <div class="container px-4 px-xl-5 my-5">
            <div class="row">
                <div class="col-12">
                    <h1 class="title--secondary">
                        Point History
                    </h1>
                    <h2 class="title--tertiary mb-5">
                        Check your point histories.
                    </h2>

                    <template v-if="histories && histories.length > 0">
                        <article
                            class="historyItem mb-2 d-flex"
                            v-for="(history, index) in histories"
                            :key="index"
                            :class="[
                                active_id && active_id == history.id
                                    ? 'addHighlight'
                                    : '',
                            ]"
                        >
                            <img
                                v-if="
                                    history.type == 'Loyalty' ||
                                        history.type == 'LOYALTY'
                                "
                                src="@/assets/img/Royalty.png"
                                alt="point image"
                                width="50"
                                height="50"
                            />
                            <img
                                v-if="history.type == 'Activity'"
                                src="@/assets/img/Mining.png"
                                alt="point image"
                                width="50"
                                height="50"
                            />
                            <img
                                v-if="history.type == 'REFERRAL'"
                                src="@/assets/img/Referal1.png"
                                alt="point image"
                                width="50"
                                height="50"
                            />
                            <p class="mb-auto mt-auto ms-2">
                                {{
                                    logForHistory(
                                        history.transaction_type,
                                        history.point,
                                        history.created_at,
                                        history.name,
                                    )
                                }}
                                <template
                                    v-if="
                                        history.pointTransactionType &&
                                            history.pointTransactionType
                                                .pointable &&
                                            history.pointTransactionType
                                                .pointable.__typename == 'Order'
                                    "
                                >
                                    <router-link
                                        :to="{
                                            name: 'MyOrder',
                                            query: {
                                                order_id:
                                                    history.pointTransactionType
                                                        .pointable.id,
                                            },
                                        }"
                                    >
                                        {{
                                            history.pointTransactionType
                                                .pointable.order_no
                                        }}
                                    </router-link>
                                </template>
                            </p>
                        </article>
                        <div class="text-center mt-4">
                            <main-button
                                v-if="has_more_page"
                                :name="$t('products.showmore.button')"
                                class="btn--lg"
                                @clicked="showMore"
                            />
                        </div>
                    </template>
                    <template v-else>
                        No Point History!
                    </template>
                    <!-- ORDER ITEM ENDS -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import ProfileSideBar from '../../components/reusable/ProfileSideBar';
import Button from '../../components/reusable/Button';

// query
import PointHistory from '../../graphql/queries/point/PointHistory.gql';
import { mapGetters } from 'vuex';

export default {
    components: {
        // 'profile-side-bar': ProfileSideBar,
        'main-button': Button,
    },

    data() {
        return {
            current_page: 1,
            has_more_page: false,
            show_more: false,
            active_id: null,
        };
    },

    mounted() {
        this.getPointHistory();
        if (this.$route.query && this.$route.query.id) {
            this.active_id = this.$route.query.id;
        }
    },

    computed: {
        ...mapGetters({
            histories: 'point/getPointHistories',
            isLoggedIn: 'auth/isLoggedIn',
        }),
    },

    methods: {
        async getPointHistory() {
            if (this.isLoggedIn) {
                try {
                    const histories = await this.$apollo.query({
                        query: PointHistory,

                        variables: {
                            page: this.current_page,
                        },
                    });

                    if (histories) {
                        // console.log(histories);
                        if (this.show_more) {
                            histories.data.pointHistory.data.map(data => {
                                this.$store.commit(
                                    'point/ADD_MORE_POINT_HISTORY',
                                    data,
                                );
                            });
                        } else {
                            await this.$store.commit(
                                'point/ADD_POINT_HISTORY',
                                histories.data.pointHistory.data,
                            );
                        }
                        this.has_more_page =
                            histories.data.pointHistory.paginatorInfo.hasMorePages;
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        },

        getOrderedDate(timestamp, deliver = false) {
            const formatDate = new Date(timestamp).toLocaleString(undefined, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                weekday: 'long',
            });
            if (deliver) {
                const currentDate = new Date(formatDate);
                currentDate.setDate(currentDate.getDate());
                const deliverDate = new Date(currentDate).toLocaleString(
                    undefined,
                    {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        weekday: 'long',
                    },
                );
                return deliverDate;
            } else {
                return formatDate;
            }
        },

        showMore() {
            this.current_page++;
            this.show_more = true;
            this.getPointHistory();
        },

        logForHistory(transaction_type, points, date, coin_type) {
            const dateTime = this.getOrderedDate(date, true);
            if (transaction_type == 'IN') {
                if (coin_type == 'login') {
                    return `You have got ${points} points for logging in at ${dateTime}.`;
                }
                if (coin_type == 'wishlist') {
                    return `You have got ${points} mining points for adding item to wishlist on ${dateTime}.`;
                }
                if (coin_type == 'selfie') {
                    return `You have got ${points} selfie points on ${dateTime}.`;
                }
                if (coin_type == 'read') {
                    return `You have got ${points} for reading blog on ${dateTime}.`;
                }
                if (coin_type == 'product') {
                    return `You have got ${points} royalty points for buying products on ${dateTime}.`;
                }
                if (coin_type == 'share') {
                    return `You have got ${points} mining points for sharing product on ${dateTime}.`;
                }
                if (coin_type == 'review') {
                    return `You have got ${points} points for reviewing product on ${dateTime}.`;
                }
                if (coin_type == 'register') {
                    return `You have got ${points} mining points for registering account on ${dateTime}.`;
                }
                if (coin_type == 'comment') {
                    return `You have got ${points} for commenting under blog/video post on ${dateTime}.`;
                }
                if (coin_type == 'referral') {
                    return `You have got ${points} referral points for referring customer on ${dateTime}.`;
                }
            } else {
                if (coin_type == 'wishlist') {
                    return `Your ${points} of mining points have been removed for deleting item from wishlist on ${dateTime}.`;
                }
                if (coin_type == 'loyal for redeem') {
                    return `Your ${points} of royalty points have been removed for redeeming package on ${dateTime}.`;
                }
                if (coin_type == 'activity for redeem') {
                    return `Your ${points} of mining points have been removed for redeeming package on ${dateTime}.`;
                }
                if (coin_type == 'referral for redeem') {
                    return `Your ${points} of referral points have been removed for redeeming package on ${dateTime}.`;
                }
                if (coin_type == 'added by admin') {
                    return `Your ${points} of referral points have been removed for redeeming package on ${dateTime}.`;
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/sass/components/profileLayout.scss';
@import '@/sass/components/profileOrder.scss';
@import '@/sass/components/orderItem.scss';

.history__box {
    grid-template-columns: minmax(100px, 150px) minmax(200px, 270px) 150px minmax(
            150px,
            313px
        );
}
.historyItem {
    background-color: #bdbdbd;
}
.addHighlight {
    border: 4px solid rgb(1, 139, 1);
}
</style>
